<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.omStatus"
          placeholder="订单状态"
          clearable
        >
          <Option
            v-for="(status, index) in statuslist"
            :key="index"
            :value="status.value"
            >{{ status.name }}</Option
          >
        </Select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.payStatus"
          placeholder="支付状态"
          clearable
        >
          <Option :value="1"  >待付款</Option
          >
          <Option
            :value="2"
            >已付款</Option
          >
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" transfer :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="订单档期"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.companyId"
          size="small"
          placeholder="所属公司"
          clearable
          @on-change="initDepartmentList"
        >
          <Option
            v-for="(channel, index) in channelCompanyList"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.departmentId"
          size="small"
          placeholder="所属部门"
          clearable
          @on-change="getChannelUserList"
        >
          <Option
            v-for="(department, index) in deparmentList"
            :key="index"
            :value="department.id"
            >{{ department.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.userId"
          placeholder="归属销售"
          clearable
          size="small"
        >
          <Option
            v-for="user in channelUsers"
            :key="user.id"
            :value="user.id"
            >{{ user.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字：客户名称、订单编号"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        ><Button
          size="small"
          type="success"
          class="m-l-5"
          @click="handleDownload"
          >导出账单</Button
        >
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <OrderDetail ref="orderDetail" />
  </div>
</template>

<script>
import OrderDetail from '@/components/order/common/ModalOrderDetail'
import { getorderstatus, getbillpage } from '@/api/order/om/order'

import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

import { ParseDate, GetDateStr, GetCurrentSchedule } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import { formatbillPayStatus, formatOmOrderStatus } from '@/utils/tagStatus'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: { OrderDetail },
  data () {
    return {
      orderSchdule: '',

      channellist: [], // 代理商列表
      channelCompanyList: [],
      deparmentList: [],
      channelUsers: [], // 代理商下所有销售
      choicechannelUsers: [], // 用于变更客户所属销售的列表
      currentCompanyName: '', // 当前代理商名称
      choiceComponyId: 0, // 当前代理商Id
      currentUserName: '', // 当前销售名称
      choiceSalerId: 0, // 当前销售ID

      statuslist: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        userId: null,
        omStatus: '',
        orderStatus: '',
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '订单编号',
          key: 'orderCode',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowOrder(params.row.orderId)
                  }
                }
              }, params.row.orderCode)
            ])
          }
        },
        { title: '客户名称', key: 'advertiserName' },
        {
          title: '发布档期',
          key: 'orderCode',
          render: (h, params) => {
            return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
          }
        },
        {
          title: '订单状态',
          align: 'center',
          render: (h, params) => {
            return formatOmOrderStatus(
              h,
              params.row.omStatus,
              params.row.omStatusName
            )
          }
        },
        {

          title: '一级销售',
          render: (h, params) => {
            return h('div', [h('p', params.row.seller || '-')])
          }
        },
        {

          title: '二级销售',
          render: (h, params) => {
            return h('div', [h('p', params.row.secondUserName || '-')])
          }
        },

        {
          title: '订单金额',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.orderTotalAmount))])
          }
        },
        {
          title: '本次应付额',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.payAmount))])
          }
        },
        {
          title: '最晚付款日',
          render: (h, params) => {
            return h('div', [h('p', params.row.lastPayDate)])
          }
        },
        {
          title: '实际付款时间',
          render: (h, params) => {
            return h('div', [h('p', params.row.payTime)])
          }
        },
        {
          title: '支付状态',
          align: 'center',
          render: (h, params) => {
            return formatbillPayStatus(
              h,
              params.row.payStatus,
              params.row.payStatusName
            )
          }
        }
        // {
        //   title: '操作',
        //   align: 'center',
        //   render: (h, params) => {
        //     const detailButton = h(
        //       'a',
        //       {
        //         style: {
        //           marginRight: '5px'
        //         },
        //         on: {
        //           click: () => {
        //             this.handleShowDetail(params.row.id)
        //           }
        //         }
        //       },
        //       '详情'
        //     )

        //     return h('div', [detailButton])
        //   }
        // }
      ]

    }
  },
  created () {
    getorderstatus().then(res => {
      this.statuslist = res
    })
    this.initChannelList()
    this.query.userId = null

    this.initPageData()
  },
  methods: {

    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },

    initChannelList () {
      getCompanyChild({
        companyId: this.$store.getters.token.userInfo.companyId,
        type:
          this.$store.getters.token.userInfo.publisherId ===
          this.$store.getters.token.userInfo.companyId
            ? 1
            : 2,
        childType: 2,
        companyType: 1
      }).then((res) => {
        let array = [
          {
            id: res.companyId,
            name: res.companyName
          }
        ]
        if (res.children.length) {
          array = array.concat(
            this.formatCompanyArray(res.children).filter(
              (x) => x.id !== res.companyId
            )
          )
        }
        this.channelCompanyList = array
        if (!this.query.companyId) {
          if (array.length > 1) {
            this.query.companyId = null
          } else {
            this.query.companyId = this.companyId
          }
        }
        this.initDepartmentList()
      })
    },
    initDepartmentList () {
      if (this.query.companyId > 0) {
        getCompanyChild({
          companyId: this.query.companyId,
          type: 2,
          childType: 2,
          companyType: 2
        }).then((res) => {
          let array = [
            {
              id: res.companyId,
              name: res.companyName
            }
          ]
          if (res.children.length) {
            array = array.concat(
              this.formatCompanyArray(res.children).filter(
                (x) => x.id !== res.companyId
              )
            )
          }
          this.deparmentList = array
          // this.query.departmentId = null
          // if (res.length > 1) {
          //   this.query.departmentId = null
          // } else {
          //   this.query.departmentId = this.companyId
          // }
          this.getChannelUserList()
        })
      } else {
        this.query.departmentId = null
        this.deparmentList = null
        this.getChannelUserList()
      }
    },
    getChannelUserList () {
      if (this.query.companyId && this.query.companyId !== '-1') {
        const data = {
          companyId: this.query.departmentId || this.query.companyId,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then((response) => {
          this.channelUsers = response.list
        })
      } else {
        this.channelUsers = []
        this.query.userId = ''
      }
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },

    initPageData () {
      getbillpage(this.query).then((response) => {
        this.total = response.total
        this.tableData = response.records
        this.query.pageNumber = response.current
        this.$store.commit('set_openmarketbill_query', this.query)
        this.$store.commit('set_openmarketbill_beginUpdate', new Date())
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    // CreateCustomer () {
    //   this.$emit('on-create')
    // },
    // handleShowDetail (customerId) {
    //   this.$emit('on-detail', customerId)
    // },

    handleDownload () {
      const filename = '账单列表(' + ParseDate(new Date()) + ').xlsx'

      // let customerStatus = ''
      // if (this.advertiserStatus !== '') {
      //   const status = []
      //   status.push(this.advertiserStatus)
      //   customerStatus = JSON.stringify(status)
      // }

      // const params = {
      //   companyStatus: customerStatus,
      //   type: this.query.type,
      //   companyId: this.query.companyId,
      //   userId: this.query.userId,
      //   keyword: this.query.keyword
      // }

      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-order/om/v1/report/download-bill-excel',
        this.query,
        filename
      )
    },

    handleShowOrder (orderId) {
      // 根据菜单动态跳转链接
      this.$refs.orderDetail.init(orderId)
    }
  },
  watch: {
    choiceComponyId (val) {
      if (val) {
        const data = {
          companyId: val,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then((response) => {
          this.choicechannelUsers = response.list
        })
      }
    }
  },
  computed: {
  }
}
</script>
